import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

const defaultConfig = {
  apiKey: "AIzaSyBA5dCJsebQY7TL9Hvu5Hv3s967Ea3aGHc",
  authDomain: "tapacenterdemo.firebaseapp.com",
  databaseURL: "https://tapacenterdemo.firebaseio.com",
  projectId: "tapacenterdemo",
  storageBucket: "tapacenterdemo.appspot.com",
  messagingSenderId: "383663430784",
  appId: "1:383663430784:web:531d6e663f7cc72e499a65",
  measurementId: "G-GVP9ZVS1S2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(defaultConfig);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
